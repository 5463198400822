import { Container } from "@/client/styles/layout";
import { styled } from "styled-components"
import { Wrapper } from "@/client/styles/Dashboard";

import { ReactComponent as MaintenanceSVG } from "@/client/assets/illustrations/maintenance.svg";

const Content = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  text-align: center;

  svg {
    border-radius: 40px;
    border: 2px solid #ffffff;
    padding: 20px 20px 0;
    max-width: 380px;
    margin: 0 0 60px;
  }

  h1 {
    margin: 0 0 20px;
  }
`;

export default function MaintenanceSection() {
  return <Wrapper>
    <Container>
      <Content>
        <MaintenanceSVG />
        <h1>Estamos aperfeiçoando os recursos para sua conta!</h1>
        <p>Essa página estará disponível em breve.</p>
      </Content>
    </Container>
  </Wrapper>
}