import AudiometriaCompleta from "@/client/assets/images/exames/audiometria-completa-audiometria-tonal-vocal-impedanciometria.png";
import AudiometriaTonalVocal from "@/client/assets/images/exames/audiometria-tonal-e-vocal.png";
import Impedanciometria from "@/client/assets/images/exames/impedanciometria-imitanciometria.png";
import OtoemissoesComTesteSupressao from "@/client/assets/images/exames/otoemissoes-com-teste-de-supressao.png";
import Eletrococleografia from "@/client/assets/images/exames/eletrococleografia.png";
import PotencialAuditivoCognitivo from "@/client/assets/images/exames/potencial-auditivo-cognitivo-P300.png";
import PotencialEvocadoAuditivo from "@/client/assets/images/exames/potencial-evocado-auditivo-de-media-e-longa-latencia.png"
import VideonistagmoscopiaComInfraVermelho from "@/client/assets/images/exames/videonistagmoscopia-com-infra-vermelho.png"
import Bera from "@/client/assets/images/exames/bera.png"
import Vectoeletronistagmografia from "@/client/assets/images/exames/vectoeletronistagmografia-computadorizada-otoneurologico-completo.png";
import PotencialMiogenico from "@/client/assets/images/exames/potencial-miogenico-evocado-vestibular-cervical-e-ocular.png";
import TesteDaOrelhinha from "@/client/assets/images/exames/emissoes-otoacusticas-transientes-teste-da-orelhinha.png";
import ReabilitacaoLabirintica from "@/client/assets/images/exames/reabilitacao-labirintica.png";
import AudiometriaDeAltasFrequencias from "@/client/assets/images/exames/audiometria-de-altas-frequencias.png";
import Acufenometria from "@/client/assets/images/exames/acufenometria.png";
import ProcessamentoAuditivoCentral from "@/client/assets/images/exames/processamento-auditivo-central-com-audiometria-inclusa.png";

export const examesAudiologicos = [
    {
        name: "Audiometria Completa (Audiometria Tonal, Vocal e Impedanciometria)",
        image: AudiometriaCompleta
    },
    {
        name: "Audiometria Tonal e Vocal",
        image: AudiometriaTonalVocal
    },
    {
        name: "Impedanciometria (Imitanciometria)",
        image: Impedanciometria
    },
    {
        name: "Otoemissões com teste de Supressão",
        image: OtoemissoesComTesteSupressao
    },
    {
        name: "Eletrococleografia (ECoGh)",
        image: Eletrococleografia
    },
    {
        name: "Potencial Auditivo Cognitivo (P300)",
        image: PotencialAuditivoCognitivo
    },
    {
        name: "Potencial Evocado Auditivo de Média e Longa Latência (MLR e LLR)",
        image: PotencialEvocadoAuditivo
    },
    {
        name: "Videonistagmoscopia com infra-vermelho",
        image: VideonistagmoscopiaComInfraVermelho
    },
    {
        name: "BERA",
        image: Bera
    },
    {
        name: "Vectoeletronistagmografia Computadorizada (Otoneurológico Completo)",
        image: Vectoeletronistagmografia
    },
    {
        name: "Potencial Miogênico Evocado Vestibular Cervical e Ocular (cVEMP e oVEMP)",
        image: PotencialMiogenico
    },
    {
        name: "Emissões Otoacústicas, Transientes e por Produto de Distorção (Teste da Orelhinha)",
        image: TesteDaOrelhinha
    },
    {
        name: "Reabilitação Labiríntica",
        image: ReabilitacaoLabirintica
    },
    {
        name: "Audiometria de Altas Frequências",
        image: AudiometriaDeAltasFrequencias
    },
    {
        name: "Acufenometria",
        image: Acufenometria
    },
    {
        name: "Processamento Auditivo Central + Audiometria Inclusa",
        image: ProcessamentoAuditivoCentral
    }
];