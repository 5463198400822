import { useContext } from "react";
import Footer from "@/client/components/Layout/Footer";
import HomeSectionOne from "@/client/sections/institutional/home/one";
import WhyChooseUsSection from "@/client/sections/institutional/WhyChooseUs";
import HomeSectionExames from "@/client/sections/institutional/home/exames-audiologicos";
import HomeSectionAparelhosAuditivos from "@/client/sections/institutional/home/aparelhos-auditivos";
import StripeReinforcement from "@/client/sections/institutional/StripeReinforcement";
import Testimonials from "@/client/sections/institutional/Testimonials";
import FormSection from "@/client/sections/institutional/Form";
import WhatsAppButton from "@/client/components/Layout/WhatsAppButton";
import { WrapperInstitutional } from "@/client/styles/Dashboard";
import { ApplicationContext } from "@/client/contexts/ApplicationContext";
import Form7Days from "@/client/sections/institutional/Form7Days";

export default function InicioInstitutionalPage() {

	const { setScroll } = useContext(ApplicationContext);

 	return <>
		<WhatsAppButton />

		<WrapperInstitutional id="WrapperInstitutional" onScroll={(e: any) => setScroll(e.target.scrollTop)}>
			<HomeSectionOne />
			<WhyChooseUsSection />
			<HomeSectionExames />
			<HomeSectionAparelhosAuditivos />
			<Form7Days />
			<StripeReinforcement />
			<Testimonials />
			<FormSection />
			<Footer />
		</WrapperInstitutional>
	</>
}
