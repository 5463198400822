import { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import { Button, Container } from "@/client/styles/layout";
import Input from "./Inputs/official";
import { ApplicationContext } from "@/client/contexts/ApplicationContext";
import { useSocket } from "@/client/contexts/SocketContext";
import usePathname from "@/client/hooks/usePathname";
import useRouter from "@/client/hooks/useRouter";
import useSearchParams from "@/client/hooks/useSearchParams";
import actions from "../actions";
import { setCookie } from "nookies";
import { EnumUserRole } from "@/core/enums/EnumUser";

enum EnumTpAcesso
{
    Logar,
    Registrar
}

const Wrapper = styled.div<{ $accesstype: EnumTpAcesso }>`
    background: var(--color-white);
    height: calc(100vh - var(--header-size));
    
    display: flex;
    align-items: ${({ $accesstype }) => $accesstype === EnumTpAcesso.Logar ? "center" : "flex-start"};
    overflow: ${({ $accesstype }) => $accesstype === EnumTpAcesso.Logar ? "hidden" : "auto"};
    padding: ${({ $accesstype }) => $accesstype === EnumTpAcesso.Logar ? "0" : "60px 0"};
`;

const Form = styled.form`
    max-width: 500px;
    margin: 0 auto;

    h3 {
        font-size: 30px;
        text-align: center;
        margin: 0 30px 60px;
    }

    button {
        width: 100%;
        max-width: 100%;
        border-radius: 6px;
        font-size: 24px;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    @media (max-width: 576px) {
        h3 {
            font-size: 26px;
        }
    }

    @media (max-width: 480px) {
        h3 {
            font-size: 22px;
            margin: 0 20px 40px;
        }
    }
`;

const ChangeAccessSection = styled.div`
    margin: 80px auto;
    max-width: max-content;
    
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
        font-size: 20px;
        font-weight: 800;
    }

    button {
        color: var(--color-primary);
        font-size: 20px;
        font-weight: 800;
        border: none!important;
        background: transparent!important;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

type FormStateTypes = {
    name: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
}

export default function AuthenticationSection()
{
    const [accessType, setAccessType] = useState<EnumTpAcesso>(EnumTpAcesso.Logar);
    const [formState, setFormState] = useState<FormStateTypes | any>({});
    const [errors, setErrors] = useState<any>(null);

    const [loading, setLoading] = useState(false);

    const { setScroll, setUser, setToken } = useContext(ApplicationContext);
    const { messages, setMessages, socket } = useSocket();

    const pathname = usePathname();
    const router = useRouter();

    const params = {
        register: Number(useSearchParams().get("registrar")),
        contactId: useSearchParams().get("id")
    };

    async function getContactData(contactId: string) {
        if(formState.email) return;

        // await actions.api.get(`/contacts/${contactId}`).then((response: any) => {
        //     if(response.data.error) return;
        //     console.log(response.data)

        //     const contactData = response.data;
        //     setFormState(contactData);
        // })
    }

    async function Submit(e: any)
    {
        e.preventDefault();

        if(loading) return;
        setLoading(true);

        await actions.api.post(`/users/${accessType === EnumTpAcesso.Logar ? 'login' : 'register'}`, formState).then(({ data }: any) => {

            const { token, user } = data as any;

            setCookie(undefined, "token", token, {
                maxAge: 60 * 60 * 24 * 365,
                path: "/"
            });

            actions.api.defaults.headers["Authorization"] = `Bearer ${token}`;
            setUser(user);
            setToken(token);
    
            if(user.role === EnumUserRole.Advogado && !pathname.startsWith("/admin")) router("/admin");

        })
        .catch(e => setErrors(e.response.data.errors))
        .finally(() => setLoading(false))
    }

    useEffect(() => {
        if(params.register === 1) setAccessType(EnumTpAcesso.Registrar);
        if(params.contactId) getContactData(params.contactId);
    }, [])

    useEffect(() => setErrors(null), [formState]);

    useEffect(() => setLoading(false), [errors]);

    useEffect(() => document.getElementById("header")?.scrollIntoView({ behavior: "smooth", block: "start" }), [accessType]);

    return <Wrapper $accesstype={accessType} onScroll={(e: any) => setScroll(e.target.scrollTop)}>
        <Container>
            <Form onSubmit={Submit}>
                <h3>{accessType === EnumTpAcesso.Registrar ? "Criar minha conta" : "Acessar minha conta"}</h3>
                { accessType === EnumTpAcesso.Registrar ? <Input type="text" placeholder="Nome completo *" error={errors?.name} value={formState?.name} onChange={(e: any) => setFormState({ ...formState, name: e.target.value })} /> : <></> }
                <Input type="email" placeholder="E-mail *" error={errors?.email} value={formState?.email} onChange={(e: any) => setFormState({ ...formState, email: e.target.value })} />
                { accessType === EnumTpAcesso.Registrar ? <Input type="tel" placeholder="Telefone *" error={errors?.phone} value={formState?.phone} onChange={(e: any) => setFormState({ ...formState, phone: e.target.value })} /> : <></> }
                <Input type="password" placeholder="Senha *" error={errors?.password} className="password" value={formState?.password} onChange={(e: any) => setFormState({ ...formState, password: e.target.value })} />
                { accessType === EnumTpAcesso.Registrar ? <Input type="password" placeholder="Confirmar Senha *" error={errors?.confirmPassword} className="password" value={formState?.confirmPassword} onChange={(e: any) => setFormState({ ...formState, confirmPassword: e.target.value })} /> : <></> }
                <Button loading={loading ? true : undefined}>Entrar</Button>
            </Form>
            <ChangeAccessSection onClick={() => setAccessType(accessType === EnumTpAcesso.Registrar ? EnumTpAcesso.Logar : EnumTpAcesso.Registrar)}>
                <span>{ accessType === EnumTpAcesso.Registrar ? "Já tem conta?" : "Ainda não tem conta?" }</span>
                <button>{ accessType === EnumTpAcesso.Registrar ? "Acessar minha conta" : "Criar minha conta" }</button>
            </ChangeAccessSection>
        </Container>
    </Wrapper>
}