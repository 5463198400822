export const depoimentos = [
    {
        youtubeCode: "0LsjSR-EWVg",
        name: "Maria das Graças Rodrigues",
        paragraphs: [
            `"Eu já estava desistindo de viver... Depois do tratamento, dos exercícios e do aparelho, eu renasci e hoje sou feliz."`
        ]
    },
    {
        youtubeCode: "g4JWkSDYg5g",
        name: "Maria Apparecida Lança Tondi",
        paragraphs: [
            `"Não ouvia direito e para não ficar perguntando, eu me afastava das pessoas... Depois do tratamento eu nasci de novo e hoje sou feliz!"`
        ]
    },
    {
        youtubeCode: "P82tj9hkSzc",
        name: "Lauro Esmerio da Silva",
        paragraphs: [
            `"Se tiver alguma coisa que está tirando seu prazer de vida, procure uma pessoa que seja capaz de te ajudar!"`
        ]
    },
    {
        youtubeCode: "WPW6gPCFPqA",
        name: "Orivaldo Martins de Souza",
        paragraphs: [
            `"Me chamavam de surdo, eu não estava surdo, eu só não entendia... Hoje eu escuto até o tiktak do relógio na minha parede!"`
        ]
    },
];