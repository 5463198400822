import { useContext, useEffect, useState } from "react";
import moment from "moment";
import 'moment/locale/pt-br';

import { ApplicationContext } from "@/client/contexts/ApplicationContext";

import { CategoryTitle, Container } from "@/client/styles/layout";
import { Wrapper, List, ListEmpty, ClientItem } from "@/client/styles/admin";

import EyeIconSvg from "@/client/assets/icons/eye.svg";
import LoadingMoreItems from "@/client/components/Loading/MoreItems";
import { IUser } from "@/core/interfaces/IUser";

export default function AdminClientsPage() {  

  const [list, setList] = useState<IUser[] | null>(null);
  const [loadingMoreItems, setLoadingMoreItems] = useState(true);

  const { setLoading, setScroll, setConversations } = useContext(ApplicationContext);

  async function getData() {
    // await actions.api.get("/admin/clients/list").then((response) => {
    //   if(!response.data.error) {
    //     setList(response.data);
    //     setTimeout(() => setLoadingMoreItems(false), 500);
    //   }
    // });
  }

  useEffect(() => {
    if(list) return;
    getData();
  }, [])

  return <Wrapper onScroll={(e: any) => setScroll(e.target.scrollTop)}>
    <Container>
      <CategoryTitle style={{ marginTop: 0 }}>Clientes</CategoryTitle>

      { list ? 
        list?.length > 0 ?
        <List>{ list?.map((item: IUser, i: any) => <Client key={i} data={item} setConversations={setConversations} />) }</List>
        :
        <ListEmpty>
          <h4>Nenhum cliente encontrado.</h4>
        </ListEmpty>
      : loadingMoreItems ? <LoadingMoreItems /> : <></> }
    </Container>
  </Wrapper>
}

const Client = ({ data, setConversations }: any) => {
  moment.locale("pt-br");

  return <ClientItem>
    <div className="data">
      <span>Cliente:</span>
      <span><span>{data?.name}</span></span>
    </div>
    <div className="data">
      <span>E-mail:</span>
      <span><span>{data?.email}</span></span>
    </div>
    <div className="data">
      <span>Cadastrou-se em:</span>
      <span><span>{moment(data?.createdAt).calendar()}</span></span>
    </div>
    <div className="actions">
      <button onClick={() => setConversations({ visible: true, section: "client", data })}><EyeIconSvg /> Ver detalhes</button>
    </div>
  </ClientItem>
}