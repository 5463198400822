import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Button, Container } from "@/client/styles/layout";
import { aparelhosAuditivos, aparelhoColorOptions } from "../../data/aparelhos-auditivos";

const Wrapper = styled.div`
    background: #F7F7F7;
    padding: 100px 0;

    h2 {
        font-size: 40px;
        font-weight: 800;
        margin: 0 0 80px;
        text-align: center;
    }

    .container Button {
        margin: 0 auto;
        padding-left: 120px;
        padding-right: 120px;
    }

    @media (max-width: 992px) {
        padding: 80px 0;

        h2 {
            font-size: 2rem;
        }
    }

    @media (max-width: 576px) {
        padding: 60px 0;
        
        h2 {
            font-size: 1.8rem;
            max-width: 320px;
            margin: 0 auto 60px;
        }

        .container Button {
            margin: 0 auto;
            padding-left: 20px;
            padding-right: 20px;
            width: 100%;
            max-width: 100%;
        }
    }

    @media (max-width: 420px) {
        h2 {
            font-size: 1.6rem;
            max-width: 320px;
        }
    }
`;

const List = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @media (max-width: 1400px) {
        gap: 30px;
    }

    @media (max-width: 1200px) {
        gap: 20px;
    }

    @media (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
        gap: 10px;
        margin: 0 -10px;
    }

    @media (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const Item = styled.div`
    border-radius: 20px;
    background-color: #E8E9ED;
    overflow: hidden;
    padding: 20px;
    cursor: pointer;

    img {
        width: 100%;
        transition: .3s ease-in-out;
    }

    &:hover {
        img {
            transform: scale(1.2);
        }
    }

    .info {
        padding: 30px;

        h3 {
            font-size: 1.4rem;
            font-weight: 800;
            color: var(--color-primary-light);
            margin: 0 0 20px;
        }

        p {
            font-size: 1.2rem;
            opacity: .9;
        }
    }

    @media (max-width: 991px) {
        .info {
            padding: 20px;

            h3 {
                font-size: 1.2rem;
                margin: 0 0 20px;
            }

            p {
                font-size: 1rem;
            }
        }
    }

    @media (max-width: 576px) {
        border-radius: 16px;

        .info {
            padding: 20px 5px;

            h3 {
                font-size: 1rem;
                margin: 0 0 10px;
            }

            p {
                font-size: .9rem;
            }
        }
    }
`;


const Choices = styled.div`
    margin-top: 100px;

    h2 {
        margin: 0 auto 40px;
    }

    .colors {
        display: flex;
        justify-content: center;
        
        circle {
            --size: 50px;
            
            min-width: var(--size);
            max-width: var(--size);
            height: var(--size);
            
            border-radius: 100%;
            margin: 0 10px;

            cursor: pointer;

            &.selected {
                transform: scale(1.2);
                border: 5px solid rgba(255,255,255,.2);
            }
        }
    }

    .product {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 0 0 40px;

        * {
            transition: .2s ease-in-out;
        }

        span {
            display: block;
            font-size: 20px;
            margin: 40px 0 60px;
            text-align: center;
            font-weight: 700;
            opacity: .7;
        }

        img {
            width: 100%;
        }
    }

    @media (max-width: 768px) {
        margin-top: 80px;

        h2 {
            font-size: 1.4rem;
        }

        .colors {
            
            circle {
                --size: 30px;
                margin: 0 5px;
            }
        }

        .product {
            span {
                margin: 40px 0;
            }
        }
    }
`;

export default function AparelhosAuditivosList()
{
    const [choiceIndex, setChoiceIndex] = useState(0);
    const navigate = useNavigate();

    return <Wrapper>
        <Container className="container">
            <h2>Os melhores aparelhos para você</h2>
            <List>
                { aparelhosAuditivos.map((item: any, i: number) =>
                    <Item key={i}>
                        <img src={item.image} alt={item.name} />
                        <div className="info">
                            <h3>{item.name}</h3>
                            { item.paragraphs.map((paragraph: string, _i: number) => <p key={_i}>{paragraph}</p>) }
                        </div>
                    </Item>
                ) }
            </List>

            <Choices>
                <h2>Escolha a sua cor favorita</h2>
    
                <div className="colors">
                    { aparelhoColorOptions.map((item: any, i: number) =>
                        <circle className={i === choiceIndex ? "selected" : ""} key={i} onClick={() => setChoiceIndex(i)} style={{ backgroundColor: item.hex }}></circle>
                    )}
                </div>
                
                <div className="product">
                    <span>{aparelhoColorOptions[choiceIndex].name}</span>
                    <img src={aparelhoColorOptions[choiceIndex].image} alt={`Aparelho Auditivo GrupoDêOuvidos - ${aparelhoColorOptions[choiceIndex].name}`} />
                </div>
            </Choices>

            <Button onClick={() => navigate("/orcamento")}>Quero receber orçamento</Button>

        </Container>
    </Wrapper>
}