import { useContext } from "react";
import Footer from "@/client/components/Layout/Footer";
import FormSection from "@/client/sections/institutional/Form";
import AparelhosAuditivosSectionOne from "@/client/sections/institutional/aparelhos-auditivos/one";
import AparelhosAuditivosList from "@/client/sections/institutional/aparelhos-auditivos/AparelhosAuditivosList";
import WhatsAppButton from "@/client/components/Layout/WhatsAppButton";
import { WrapperInstitutional } from "@/client/styles/Dashboard";
import { ApplicationContext } from "@/client/contexts/ApplicationContext";
import Testimonials from "@/client/sections/institutional/Testimonials";
import WhyChooseUsSection from "@/client/sections/institutional/WhyChooseUs";
import Form7Days from "@/client/sections/institutional/Form7Days";

export default function AparelhosAuditivosInstitutionalPage() {

  const { setScroll } = useContext(ApplicationContext);

  return (
    <>
      <WhatsAppButton />

      <WrapperInstitutional id="WrapperInstitutional" onScroll={(e: any) => setScroll(e.target.scrollTop)}>
        <AparelhosAuditivosSectionOne />
        <AparelhosAuditivosList />
        <Form7Days />
        <Testimonials />
        <WhyChooseUsSection />
        <FormSection />
        <Footer />
      </WrapperInstitutional>
    </>
  )
}
