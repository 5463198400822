import { useContext, useState } from "react";
import Footer from "@/client/components/Layout/Footer";
import FormSection from "@/client/sections/institutional/Form";
import WhatsAppButton from "@/client/components/Layout/WhatsAppButton";
import { WrapperInstitutional } from "@/client/styles/Dashboard";
import { ApplicationContext } from "@/client/contexts/ApplicationContext";

export default function ContatoInstitutionalPage({ type }: { type: "contato" | "orcamento" | "agendamento"}) {

  const { setScroll } = useContext(ApplicationContext);

  const title = () => {
    if (type === "agendamento") return "Realize o agendamento";
    if (type === "orcamento") return "Solicite orçamento";

    return "Entre em contato";
  }

  return (
    <>
      <WhatsAppButton />
      <WrapperInstitutional id="WrapperInstitutional" onScroll={(e: any) => setScroll(e.target.scrollTop)}>
        <FormSection title={title()} />
        <Footer />
      </WrapperInstitutional>
    </>
  )
}
