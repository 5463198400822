import styled from "styled-components";

export const DashboardOptions = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, 360px);
	gap: 20px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

export const DashboardOption = styled.div<{ $notification?: number }>`
	padding: 20px 30px;
	border-radius: 10px;
	background-color: #fff;
	height: 100px;
	box-shadow: 0 0 40px rgba(0,0,0,.05);

	display: grid;
	grid-template-columns: auto 1fr;
	align-items: center;
	position: relative;

	user-select: none;
	-o-user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	border: 2px solid transparent;

	cursor: pointer;
	transition: 200ms ease-in-out;

	&:hover {
		border-color: #cfcfcf;
	}

	> :first-child {
		margin: 0 20px 0 -4px;
	}
	
	.number {
		font-size: 38px;
		font-weight: 800;
		text-align: center;
	}

	svg, .number {
		--size: 50px;
		width: var(--size);
		min-width: var(--size);
		height: var(--size);
	}

	h2 {
		font-size: 20px;
		margin: 0 0 3px;
	}

	p {
		font-size: 16px;
		line-height: 1.2;

		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 100%;
	}

	${({ $notification }) => ($notification && $notification > 0) && `
		&::after {
			content: '${$notification}';
			background: var(--color-primary);
			color: white;
			
			position: absolute;
			top: -5px;
			right: -5px;
			
			--size: 30px;
			min-width: var(--size);
			min-height: var(--size);
			width: max-content;
			max-height: var(--size);
			border-radius: 100%;
			font-size: calc(var(--size) - 12px);
			padding: 1px 0 0;

			display: flex;
			align-items: center;
			justify-content: center;
		}
	`};

	@media (max-width: 480px) {
		svg, .number {
			--size: 40px;
		}
	}
`;