import styled from "styled-components"

export const Wrapper = styled.div<{ $active: boolean }>`
  height: 4px;
  width: 100%;

  position: fixed;
  z-index: 99999999;
  top: 0;

  opacity: ${({ $active }) => $active ? '1' : '0'};
  transition: 50ms ease-in-out 200ms;

  > div {
    width: 40%;
    height: 100%;
    background: var(--color-primary);
    border-radius: 10px;
    opacity: .9;

    animation: ${({ $active }) => $active ? '1.5s LoadingLineAnimation infinite' : ''};

    @keyframes LoadingLineAnimation {
      0% {
        transform: translateX(0);
      }
      80% {
        transform: translateX(100vw);
      }
      100% {
        transform: translateX(100vw);
      }
    }
  }
`;

export default function LoadingLine({ active }: any) {
  return <Wrapper $active={active}>
    <div></div>
  </Wrapper>
}