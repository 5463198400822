import { useContext } from "react";

import WhatsAppButton from "@/client/components/Layout/WhatsAppButton";
import ExamesAudiologicosSectionOne from "@/client/sections/institutional/exames-audiologicos/one";
import ExamesAudiologicosList from "@/client/sections/institutional/exames-audiologicos/ExamesAudiologicosList";
import StripeReinforcement from "@/client/sections/institutional/StripeReinforcement";
import FormSection from "@/client/sections/institutional/Form";
import Footer from "@/client/components/Layout/Footer";
import { WrapperInstitutional } from "@/client/styles/Dashboard";
import { ApplicationContext } from "@/client/contexts/ApplicationContext";
import Testimonials from "@/client/sections/institutional/Testimonials";

export default function ExamesAudiologicosInstitutionalPage() {

  const { setScroll } = useContext(ApplicationContext);

  return (
    <>
      <WhatsAppButton />

      <WrapperInstitutional id="WrapperInstitutional" onScroll={(e: any) => setScroll(e.target.scrollTop)}>
        <ExamesAudiologicosSectionOne />
        <ExamesAudiologicosList />
        <Testimonials />
        <StripeReinforcement />
        <FormSection />
        <Footer />
      </WrapperInstitutional>
    </>
  )
}