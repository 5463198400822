export const DEV = process.env.NODE_ENV !== "production";

export const SERVER_DOMAIN = DEV ? "192.168.1.67" : "192.168.1.67";
export const SERVER_PORT = DEV ? 3000 : 3000;

export const CLIENT_DOMAIN = DEV ? "192.168.1.67" : "192.168.1.67";
export const CLIENT_PORT = DEV ? 5000 : 5000;

export const SERVER_HOSTNAME = `http${!DEV ? "s" : ""}://${SERVER_DOMAIN}${SERVER_PORT > 0 ? `:${SERVER_PORT}` : ""}`;
export const CLIENT_HOSTNAME = `http${!DEV ? "s" : ""}://${CLIENT_DOMAIN}:${CLIENT_PORT > 0 ? `:${CLIENT_PORT}` : ""}`;

export const MAINTENANCE = false;

export const GOOGLE = {
    storage: {
        bucket: "afs-cdn",
        baseUrl: "https://storage.googleapis.com",
        publicUrl: "https://storage.googleapis.com/afs-cdn", // Concatenado diretamente
        limitFileSize: 1024 * 1024 * 20 // 20 MB
    },
    gmail: {
        email: "contato@saturniatecnologia.com.br",
        name: "Saturnia Tecnologia"
    }
}