import styled from "styled-components";
import LogoWhite from "@/client/assets/logo-white.png";
import { ReactComponent as PhoneIcon } from "@/client/assets/icons/phone-light.svg";
import { ReactComponent as FacebookIcon } from "@/client/assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "@/client/assets/icons/instagram.svg";
import { ReactComponent as YouTubeIcon } from "@/client/assets/icons/youtube.svg";
import { ReactComponent as WhatsAppIcon } from "@/client/assets/icons/whatsapp.svg";
import { ReactComponent as EmailIcon } from "@/client/assets/icons/mail.svg";
import { ReactComponent as SaturniaLogo } from "@/client/assets/logo-saturnia.svg";
import { Container } from "@/client/styles/layout";
import actions from "@/client/actions";

const FooterWrapper = styled.footer`
	background-color: #9ad6ce;

	.container {
		display: grid;
		grid-template-areas: "UNIT UNIT UNIT" "INFO LINKS LINKS";
	}

	@media (max-width: 768px) {
		.container {
			display: grid;
			grid-template-areas: "UNIT" "LINKS" "INFO";
		}
	}

	a, button {
		color: #006356!important;
	}
`;

const FooterContent = styled.div`
  	padding: 80px 0;

	.logo {
		grid-area: LOGO;
		margin: 40px auto;
		max-width: 300px;
		display: none;
	}

	@media (max-width: 768px) {
		padding: 30px 0 0;
	}
`;

const FooterSection = styled.div`
	flex: 1;
	margin: 20px;

	h4 {
		font-size: 1.3rem;
		margin-bottom: 15px;
		color: #006356;
		font-weight: 800;
	}

	p, a {
		font-size: 1rem;
		color: #006356;
		margin: 5px 0;
	}

	a {
		text-decoration: none;
		display: block;
	}

	&.info-section {
		grid-area: INFO;

		p {
			font-size: 1rem;
			font-weight: 700;
		}

		.contact {
			display: flex;
			margin: 20px 0;

			button, a {
				--size: 50px;
				min-width: var(--size);
				max-width: var(--size);
				height: var(--size);
				border: none;

				cursor: pointer;

				display: flex;
				align-items: center;
				justify-content: center;
				background-color: transparent;
				border-radius: 50%;
				margin-right: 10px;

				padding: 10px;

				background: rgb(10 129 114 / 10%);

				svg {
					width: 22px;
					height: 22px;

					path {
						fill: #004f1d;
					}
				}

				&.stroke {
					path {
						stroke: #004f1d;
						fill: transparent;
					}
				}

				&:hover {
					background: rgb(10 129 114 / 30%);
				}
			}
		}
	}

	&.links-section {
		grid-area: LINKS;

		a {
			font-size: 1.2rem;
			font-weight: 700;
			transition: .2s ease-in-out;
			padding: 6px 0;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	&.socials-section {
		grid-area: SOCIALS;

		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

	
`;

const Units = styled.div`
	grid-area: UNIT;

	display: grid;
	grid-template-columns: 1.5fr 1.4fr 1.4fr 1.4fr;
	gap: 30px;

	border-bottom: 1px solid rgb(10 129 114 / 25%);
	padding-bottom: 40px;
	margin-bottom: 40px;
	
	@media (max-width: 768px) {
		flex-direction: column;
	}

	.unit {
		flex: 1;
		min-width: 200px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;

		p {
			font-size: 1.05rem;
			font-weight: 700;
			color: rgba(0,0,0,.8);
		}

		.title {
			h4 {
				color: #006356;
				font-size: 1.3rem;
				font-weight: 800;
			}

			h5 {
				color: #006356;
				font-size: 1rem;
				font-weight: 700;
				margin-bottom: 10px;
			}
		}

		.location {
			margin: 10px 0;
			height: 100%;
		}

		.phone {
			margin: 10px 0 0;

			button {
				font-size: 1.2rem;
				font-weight: 700;
				
				border: 2px solid rgb(10 129 114 / 40%);
				background: transparent;
				margin: 0 0 5px;
				padding: 10px 20px;
				border-radius: 10px;
				width: 100%;

				display: flex;
				align-items: center;

				color: #004f1d;
				cursor: pointer;

				svg {
					width: 22px;
					margin-right: 10px;
				}

				&:first-child {
					svg path {
						stroke: #004f1d;
					}
				}

				&:last-child {
					svg path {
						fill: #004f1d;
					}
				}

				&:hover {
					background: rgb(10 129 114 / 10%);
				}
			}
		}
	}

	@media (max-width: 1400px) {
		.unit {
			.title {
				h4 {
					font-size: 1.2rem;
				}

				h5 {
					font-size: 1rem;
				}
			}
		}
	}

	@media (max-width: 1100px) {
		grid-template-columns: 1fr 1fr;
		gap: 60px 40px;
	}

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
		gap: 50px;
		padding: 20px 20px 60px;
	}
`;

const FooterBottom = styled.div`
	background-color: black;
	color: #fff;
	text-align: center;
	font-size: 0.9rem;
	z-index: 1000;
	position: relative;
	padding: 30px 0;

	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	p {
		margin: 5px 0;
		display: flex;
		font-size: 1.2rem;
	}

	.logo-saturnia {
		height: 24px;
		min-height: 24px;
		margin-left: 10px;
	}

	a {
		color: #fff;
		text-decoration: none;
		font-weight: bold;
	}

	@media (max-width: 1100px) {
		p {
			font-size: 1rem;
			font-weight: 700;
		}

		.logo-saturnia {
			height: 20px;
		}
	}

	@media (max-width: 1100px) {
		min-height: 150px;
		
		.container {
			flex-direction: column;
		}
	}

	@media (max-width: 768px) {
		min-height: 150px;
		padding: 20px 20px 100px;
		
		p {
			font-size: .9rem;
			margin-bottom: 20px;
			max-width: 280px;

			&:last-child {
				display: flex;
				flex-direction: column;
			}

			a {
				margin-top: 10px;
			}
		}
	}
`;

export default function Footer() {

	

	return (
		<FooterWrapper>
			<FooterContent>
				<Container className="container">
					<Units>
						<div className="unit">
							<div className="title">
								<h4>São Bernardo do Campo, SP</h4>
								<h5>UNIDADE I - Centro</h5>
							</div>
							<div className="location">
								<p>Rua Jurubatuba, 1350</p>
								<p>Sala 04 (Térreo)</p>
								<p>CEP: 09725-000 - SP</p>
							</div>
							<div className="phone">
								<button onClick={() => actions.phoneSend("551135364554")}><PhoneIcon /> (11) 3536-4554</button>
								<button onClick={() => actions.whatsAppSend("5511992449217")}><WhatsAppIcon /> (11) 99244-9217</button>
							</div>
						</div>
						<div className="unit">
							<div className="title">
								<h4>Penha de França - SP</h4>
								<h5>UNIDADE II</h5>
							</div>
							<div className="location">
								<p>Rua General Sócrates, 216</p>
								<p>Salas 97 e 98</p>
								<p>CEP: 03632-040 - SP</p>
							</div>
							<div className="phone">
								<button onClick={() => actions.phoneSend("551145027900")}><PhoneIcon /> (11) 4502-7900</button>
								<button onClick={() => actions.whatsAppSend("5511939511631")}><WhatsAppIcon /> (11) 93951-1631</button>
							</div>
						</div>
						<div className="unit">
							<div className="title">
								<h4>Moema - São Paulo</h4>
								<h5>UNIDADE III</h5>
							</div>
							<div className="location">
								<p>Avenida Miruna, 162</p>
								<p>CEP: 04084-000 - SP</p>
							</div>
							<div className="phone">
								<button onClick={() => actions.phoneSend("551123377908")}><PhoneIcon /> (11) 2337-7908</button>
								<button onClick={() => actions.whatsAppSend("5511994880122")}><WhatsAppIcon /> (11) 99488-0122</button>
							</div>
						</div>
						<div className="unit">
							<div className="title">
								<h4>Lapa - São Paulo</h4>
								<h5>UNIDADE IV</h5>
							</div>
							<div className="location">
								<p>Rua Cerro Corá, 1073</p>
								<p>CEP: 05061-250 - SP</p>
							</div>
							<div className="phone">
								<button onClick={() => actions.phoneSend("551130232026")}><PhoneIcon /> (11) 3023-2026</button>
								<button onClick={() => actions.whatsAppSend("5511939523336")}><WhatsAppIcon /> (11) 93952-3336</button>
							</div>
						</div>
					</Units>

					<img src={LogoWhite} className="logo" />

					<FooterSection className="info-section">
						<h4>Grupo Dê Ouvidos</h4>
						<p>Fono & Audio Quality</p>
						<p>03.920.520/0001-00</p>
						<p>contato@grupodeouvidos.com.br</p>
						<div className="contact">
							<button className="stroke" onClick={() => actions.emailSend()}><EmailIcon /></button>
							<button onClick={() => actions.whatsAppSend()}><WhatsAppIcon /></button>
							<a href="https://instagram.com/grupodeouvidos"><InstagramIcon /></a>
							<a href="https://facebook.com/deouvidos" className="stroke"><FacebookIcon /></a>
							<a href="https://www.youtube.com/channel/UCQvPmcqI8rkxp9MAFm59-Bg" className="stroke"><YouTubeIcon /></a>
						</div>
					</FooterSection>
					
					<FooterSection className="links-section">
						<h4>Links úteis</h4>
						<a href="/quem-somos">Quem somos</a>
						<a href="/depoimentos">Depoimentos</a>
						<a href="/exames-audiologicos">Exames audiológicos</a>
						<a href="/aparelhos-auditivos">Aparelhos auditivos</a>
						<a href="/contato?subject=TrabalheConosco">Trabalhe conosco</a>
						<a href="/admin">Administração</a>
						<a href="/contato">Entre em contato</a>
					</FooterSection>

				</Container>
			</FooterContent>
			<FooterBottom>
				<Container className="container">
					<p>Copyright © 2024 Grupo Dê Ouvidos. Todos os direitos reservados.</p>
					<p>Desenvolvido por <a href="https://saturniatecnologia.com.br" target="_blank"><SaturniaLogo className="logo-saturnia"/></a></p>
				</Container>
			</FooterBottom>
		</FooterWrapper>
  	);
}
