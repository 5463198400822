import { styled } from "styled-components";

export const WrapperInstitutional = styled.div`
	height: calc(100vh - var(--header-size));
	overflow: auto;

	@media (max-width: 991px) {
		height: calc(100vh - var(--header-size) + 20px);
	}
`;

export const Wrapper = styled.div`
	padding: 80px 0;
	height: calc(100vh - var(--header-size));
	background: var(--color-dashboardBackground);
	color: #333;

	overflow: auto;

	h1 {
		font-size: 30px;
	}

	@media (max-width: 991px) {
		height: 100vh;
		padding: 60px 0 260px;
	}

	@media (max-width: 768px) {
		h1 {
			font-size: 26px;
		}
	}

	@media (max-width: 576px) {
		h1 {
			font-size: 24px;
		}
	}
`;

export const ListEmpty = styled.div`
	width: 100%;
	background: var(--color-white);
	padding: 50px 30px;

	border-radius: 10px;

	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	svg {
		height: 80px;
		margin: 0 0 30px;
	}

	h4 {
		font-size: 20px;
		margin: 0;
	}

	button {
		border-radius: 100px;
	}
`;

export const List = styled.div<{ columns?: number }>`
	display: grid;
	grid-template-columns: ${({ columns }) => `repeat(${columns || 1}, 1fr)`};
	gap: 20px;

	@media (max-width: 991px) {
		grid-template-columns: 1fr;
	} 
`;

export const DocumentItem = styled.div`
	padding: 10px;
	border-radius: 10px;
	margin: 0 0 10px;
	background: var(--color-white);

	--image-size: 50px;
	
	display: grid;
	grid-template-columns: var(--image-size) 2fr 100px 1fr 0 1fr;
	align-items: center;
	gap: 20px;

	picture {
		cursor: pointer;
	}

	picture, .pdf {
		object-fit: cover;
    min-width: var(--image-size);
		max-width: var(--image-size);
		height: var(--image-size)!important;
    border-radius: 6px;
    overflow: hidden;
	}
	
	picture {
		display: block;
		background-color: rgba(0,0,0,.1);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}

	.pdf {
		background: #8e5554;

		display: flex;
		justify-content: center;
		align-items: center;

		&::after {
			content: "PDF";
			font-size: 14px;
			color: #FFF;
		}
	}

	.title {
		font-weight: 800;
		font-size: 16px;
		opacity: .9;
	}

	.date, .size {
		font-size: 16px;
		font-weight: 800;
		text-align: center;
		opacity: .9;
	}

	.actions {
		display: flex;
		justify-content: flex-end;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 10px;

			font-size: 14px;
			font-weight: 800;
			padding: 12px 20px;
			border: none;
			border-radius: 8px;

			opacity: .8;
			transition: 200ms ease-in-out;

			&:hover {
				opacity: 1;
			}

			cursor: pointer;

			svg {
				margin-right: 10px;

				--svg-size: 16px;
				min-width: var(--svg-size);
				max-width: var(--svg-size);
				height: var(--svg-size);

				fill: #333;
				
				path {
					fill: #333;
				}
			}

			&.download {
				background: #D9D9D9;
				color: #333;
			}

			&.remove {
				background: #C6AAA9;
				color: var(--color-primary);

				svg {
					fill: var(--color-primary);
				
					path {
						fill: var(--color-primary);
					}
				}
			}
		}
	}

	@media (max-width: 1280px) {
		grid-template-columns: auto 1fr;
	}

	@media (max-width: 991px) {
		gap: 10px;

		.title {
			text-align: right;
		}

		.date {
			text-align: right;
		}

		.size {
			text-align: left;
			font-size: 14px;
		}
		
		.actions {
			margin: 10px 0 0;
		}
	}

`;

export const ClientItem = styled.div`
	padding: 12px 12px 12px 20px;
	width: 100%;
	background: #fff;
	border-radius: 8px;

	display: grid;
	grid-template-columns: 1fr 2fr 120px 120px 150px auto;
	gap: 30px;

	margin: 0 0 10px;

	span {
		font-size: 16px;
		line-height: 1.4;
		text-overflow: ellipsis;
		overflow: hidden;

		span {
			font-weight: 800;
		}
	}

	.status {
		&.success { color: rgba(82, 171, 86, 1); }
		&.in-progress { color: rgba(220, 164, 77, 1); }
		&.analytics { color: rgba(142, 85, 84, 1); }
	}

	.data {
		display: flex;
		flex-direction: column;

		max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	}

	.actions {
		display: flex;
		justify-content: flex-end;

		button {
			border: none;

			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 15px;
			font-weight: 800;
			width: max-content;
			padding: 10px 20px;
			border-radius: 10px;

			cursor: pointer;
			background: rgba(142, 85, 84, 1);
			color: #fff;

			transition: 200ms ease-in-out;

			&:hover {
				background: var(--color-primary-light);
			}

			svg {
				min-width: 18px;
				max-width: 18px;
				height: 18px;
				fill: #fff;
				margin: 0 10px 0 0;

				path {
					fill: #fff;
				}
			}
		}
	}

	@media (max-width: 1280px) {
		grid-template-columns: 1fr 1fr;
		gap: 10px;
	}

	@media (max-width: 991px) {
		grid-template-columns: 1fr;
		gap: 10px;
	}

	@media (max-width: 380px) {
		padding: 15px;
	}
`;

export const ClientDetails = styled.div`
	h4 {
		font-size: 22px;
		font-weight: 800;
		margin: 0 0 40px;
	}

	.data {
		display: flex;
		flex-direction: column;
		margin: 20px 0 0;

		max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

		span {
			font-size: 16px;
			text-overflow: ellipsis;
    	overflow: hidden;

			span {
				font-weight: 800;
			}
		}

		&.documents {
			h4 {
				margin: 20px 0;
			}

			.document {
				background-color: var(--color-hover);
				border-radius: 8px;
				margin: 0 0 6px;
				padding: 6px;

				display: flex;
				align-items: center;

				&.empty {
					padding: 14px 20px;
					opacity: .8;
					background-color: transparent;
					border: 3px dashed var(--color-hover);
				}

				.image {
					width: 40px;
					min-width: 40px;
					height: 40px;
					border-radius: 6px;
					background-color: rgba(0,0,0,.2);
					background-size: cover;
					background-position: center;
				}

				.title {
					font-weight: 800;
					font-size: 16px;
					opacity: .9;
					margin: 0 15px;
					width: 100%;
				}

				button {
					margin-right: 3px;
					display: flex;
					align-items: center;
					padding: 8px 14px;
					font-size: 16px;
					height: 100%;
					font-weight: 800;
					color: rgba(0,0,0,.6);
					border: 1px solid rgba(0,0,0,.2);
					border-radius: 8px;
					
					background: transparent;
					cursor: pointer;
					transition: 200ms ease-in-out;

					&:hover {
						background: #f1f1f1;
					}

					svg {
						--size: 14px;
						min-width: var(--size);
						max-width: var(--size);
						height: var(--size);
						margin: 0 10px;
					}
				}
			}
		}
	}
`;

export const CommitmentItem = styled.div`
	padding: 20px 20px 20px 25px;
	border-radius: 10px;

	border: 2px dashed #ffd571;
	background: #ffd57169;

	width: 100%;

	h5 {
		font-size: 30px;
		font-weight: 800;
		margin: 10px 0;
		line-height: 1.2;
	}
	
	h6 {
		font-size: 18px;
		font-weight: 800;
		margin: 14px 0 0;
		line-height: 1.2;
		width: 100%;
		padding: 20px 0 0;

		border-top: 1px solid rgba(0,0,0,.1);

		span {
			color: var(--color-primary-light);
		}
	}

	&.archived {
		background: #d3d3d3;
    border-color: #bfbfbf;
		opacity: .9;

		.date {
			background: #bdbdbd;
			color: #333;
		}
	}

	@media (max-width: 991px) {
		h5 {
			font-size: 24px;
		}
	}

	@media (max-width: 991px) {
		h5 {
			font-size: 20px;
		}
	}

	@media (max-width: 680px) {
		padding: 20px;
			
		h5 {
			font-size: 24px;
			margin: 5px 0;
		}
	}

	@media (max-width: 480px) {
		h6 {
			font-size: 16px;
		}
	}

`;
