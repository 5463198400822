import React, { createContext, useContext, useEffect, useState, ReactNode, Context } from 'react';

import { ApplicationContext } from './ApplicationContext';
import { IChat } from './AdminContext';
import { IAttachment, ICommitment, IMessage } from '@/core/interfaces/IMessage';
import { SERVER_HOSTNAME } from '@/configs';
import { useInactivityTimer } from '../hooks/useInactivityTimer';

interface SocketProviderProps {
  children: ReactNode;
}

interface ISocketContext {
  sendNewMessage: (message: string) => void;
  sendNewDocument: (attachment: IAttachment) => void;
  sendNewCommitment: (message: string, compromisso: ICommitment) => void;
  
  socket: WebSocket;

  chats: IChat[] | null;
  setChats: React.Dispatch<React.SetStateAction<IChat[] | null>>;

  chatSelected: IChat | null;
  setChatSelected: React.Dispatch<React.SetStateAction<IChat | null>>;
  
  messages: IMessage[] | null;
  setMessages: React.Dispatch<React.SetStateAction<IMessage[] | null>>;
}

export const SocketContext: Context<ISocketContext> = createContext({} as ISocketContext);

export function useSocket() {
  const context = useContext(SocketContext);
  if (context === undefined) {
    throw new Error('useSocket must be used within a SocketProvider');
  }
  return context;
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {

  	const [messages, setMessages] = useState<IMessage[] | null>(null);
	const [limitMessages, setLimitMessages] = useState(10000);

	const [socket, setSocket] = useState<WebSocket | null>(null) as any;
	const [chats, setChats] = useState<IChat[] | null>(null) as any;
	const [chatSelected, setChatSelected] = useState<IChat | null>(null) as any;
	const { token, user, isAdmin } = useContext(ApplicationContext);

	const isActive = useInactivityTimer(5000);

	useEffect(() => {
		if (!user || socket) return;

		const initializeWebSocket = () => {
			const sockethost = SERVER_HOSTNAME.replace("http", "ws");
			const webSocket = new WebSocket(`${sockethost}/${user?._id}`);
	
			webSocket.onopen = () => {
			};
	
			webSocket.onclose = (event) => {
				console.log('Conexão WebSocket finalizada.');
				if (!event.wasClean) {
					console.log("Tentando reconectar...");
					setTimeout(initializeWebSocket, 1000); // Reconexão
				}
			};
	
			webSocket.onerror = (event) => {
				console.error("Erro na conexão WebSocket:", event);
			};
	
			setSocket(webSocket);
			
			return () => {
				webSocket.close();
			};
		};

		initializeWebSocket();
	}, [user]);

	useEffect(() => {
		if (socket) socket.send(JSON.stringify({ type: "user-activity", isActive }))
	}, [isActive])

	async function sendMessage(event: any)
	{
		event.channel = !isAdmin ? user!._id : chatSelected?._id;
		socket.send(JSON.stringify(event));
	}

	const contextData: ISocketContext = {

		sendNewMessage: async (message: string) =>
		{
			sendMessage({ type: "new-message", message });
		},
		sendNewDocument: async (attachment: IAttachment) =>
		{
			sendMessage({ type: "new-attachment", attachment });
		},
		sendNewCommitment: async (message: string, commitment: ICommitment) =>
		{
			commitment.to = user?._id;
			commitment.from = chatSelected?._id;

			sendMessage({ type: "new-commitment", message, commitment });
		},

		socket,

		chats,
		setChats,

		chatSelected,
		setChatSelected,

		messages,
		setMessages,
	}

	return (
		<SocketContext.Provider value={contextData}>
			{children}
		</SocketContext.Provider>
	);
};
