import { AdminProvider } from "@/client/contexts/AdminContext";
import { ApplicationProvider } from "@/client/contexts/ApplicationContext";

export default function AdminLayoutRoot({ children, title }: any)
{
	return <>
	  <ApplicationProvider title={title} isDashboard={true}>
		<AdminProvider>
		  {children}
		</AdminProvider>
	  </ApplicationProvider>
	</>
}