
export type ContactTypes = {
  name: string;
  email: string;
  phone: string;
  message: string;
}

export function ContactValidation({ name, email, phone, message }: ContactTypes) {
  var errors = {} as ContactTypes;

  if(!name || name?.length < 1) errors.name = "Obrigatório";
  
  if(!email || email?.length < 1) errors.email = "Obrigatório";
  if(!email?.includes("@") || !email?.includes(".")) errors.email = "E-mail inválido";
  
  if(!phone) errors.phone = "Obrigatório";
  if(phone?.length < 8) errors.phone = "Número inválido";

  if(!message || message?.length < 1) errors.message = "Obrigatório";
  if(message?.length < 8) errors.message = "Mensagem muito curta";

  return errors;
}