export function formatFullName(name?: string | null)
{
  if(!name) return "";
  const nameSplited = name?.trim()?.split(" ");
  let userName = "";
  
  nameSplited.forEach((namePart: string, index: number) => {

    const linkNames = [ "da", "de", "di", "do", "du", "das", "dos" ];

    if(index === 0 || index === nameSplited?.length-1) return userName += ` ${namePart}`;

    if(linkNames?.filter(link => link === namePart)[0]) return userName;
    
    return userName += ` ${namePart?.slice(0,1)}.`;
  })

  return userName;
}