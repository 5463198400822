import moment from "moment";
import { useContext } from "react";

import { Wrapper } from "@/client/styles/Dashboard";
import { CategoryTitle, Container } from "@/client/styles/layout";
import { ApplicationContext } from "@/client/contexts/ApplicationContext";
import LinksUteis from "@/client/components/LinksUteis";

import { DashboardOption, DashboardOptions } from "@/client/styles/Dashboard/home";

import { ReactComponent as CompromissosSvg } from "@/client/assets/illustrations/reminders.svg";
import { ReactComponent as MessagesSvg } from "@/client/assets/illustrations/conversations.svg";
import { ReactComponent as ClientsSvg } from "@/client/assets/illustrations/clients.svg";
import { AdminContext } from "@/client/contexts/AdminContext";
import useRouter from "@/client/hooks/useRouter";

export default function AdminHomePage()
{
	const { user, setScroll } = useContext(ApplicationContext);
	const { stats } = useContext(AdminContext);
	
	const router = useRouter();

	function getRegards()
	{
		const date = moment();
		const hour = date.hour();
		
		if(hour >= 0 && hour < 12) return "Bom dia";
		if(hour >= 12 && hour < 18) return "Boa tarde";
		if(hour >= 18 && hour < 24) return "Boa noite";

		return "Olá";
	}

	return <Wrapper onScroll={(e: any) => setScroll(e.target.scrollTop)}>
		<Container>
			<h1>{getRegards()}, {user?.name?.split(" ")[0]}!</h1>

			<CategoryTitle>Visão geral</CategoryTitle>
			
			<DashboardOptions>

				<DashboardOption $notification={stats?.compromissos} onClick={() => router("/admin/compromissos")}>
					<CompromissosSvg style={{ padding: 2 }} />
					<div className="info">
						<h2>Compromissos</h2>
						<p>Lista de compromissos</p>
					</div>
				</DashboardOption>

				<DashboardOption $notification={stats?.messages} onClick={() => router("/admin/atendimentos")}>
					<MessagesSvg />
					<div className="info">
						<h2>Atendimento</h2>
						<p>Mensagem não lidas</p>
					</div>
				</DashboardOption>

				<DashboardOption $notification={stats?.clients} onClick={() => router("/admin/clientes")}>
					<ClientsSvg />
					<div className="info">
						<h2>Clientes</h2>
						<p>Lista completa</p>
					</div>
				</DashboardOption>

			</DashboardOptions>

			<LinksUteis />

		</Container>
	</Wrapper>
}