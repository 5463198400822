import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";

import { Button, Container } from "@/client/styles/layout";

import BackgroundPattern from "@/client/assets/images/background-pattern.png";
import CheckIcon from "@/client/assets/icons/check-colored-green.svg";
import MainImage from "@/client/assets/images/component-aparelhos-auditivos-page.png";

const Wrapper = styled.div`
  min-height: calc(100vh - var(--header-size));
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-light);
  background-image: url(${BackgroundPattern});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  color: var(--white);

  position: relative;

  @media (max-width: 991px) {
	min-height: 1000px;
    padding: 60px 0;
	align-items: flex-start;
  }

  @media (max-width: 576px) {
	min-height: 850px;
  }

  @media (max-width: 480px) {
	min-height: 750px;
  }

  @media (max-width: 420px) {
	min-height: 700px;
  }
`;

const ContentWrapper = styled.div`
	margin: 0 auto 0 0;
  	color: var(--color-white);

	h1 {
		font-size: 3rem;
    	font-weight: 400;
		max-width: 540px;

		span {
			font-weight: 800;
		}
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 60px 0;

		li {
			display: flex;
			align-items: center;
			font-size: 1.3rem;
			font-weight: 700;
			max-width: 380px;
			margin: 0 0 20px;

			img {
				margin-right: 15px;
				
				--size: 30px;
				height: var(--size);
				max-width: var(--size);
				min-width: var(--size);
			}
		}
	}

	@media (max-width: 1400px) {
        h1 {
            font-size: 2.6rem;
            max-width: 480px;
        }

		ul {
          margin: 40px 0;
          text-align: left;
        }
    }

	@media (max-width: 1200px) {
        h1 {
            font-size: 2.4rem;
            max-width: 420px;
        }

		ul {
          margin: 30px 0;

		  li {
			font-size: 1.2rem;
			margin-bottom: 10px;

			img {
				--size: 25px;
			}
		  }
        }
    }

    @media (max-width: 992px) {
        text-align: center;
        margin: 0 auto;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    @media (max-width: 576px) {
        h1 {
            font-size: 2.2rem;
            max-width: 380px;
        }

        ul {
          margin: 30px 0;

          li {
            font-size: 1.2rem;
            margin-bottom: 14px;

            img {
              --size: 25px;
            }
          }
        }
    }

    @media (max-width: 480px) {
        h1 {
            font-size: 2rem;
            max-width: 350px;
        }

        ul {
			margin: 30px 20px;

			li {
				font-size: 1.1rem;

				img {
				--size: 25px;
				}
			}
        }
    }

	@media (max-width: 480px) {
        h1 {
            font-size: 1.8rem;
            max-width: 320px;
        }

        ul {
			margin: 30px 0;

          li {
			max-width: 320px;

            img {
              --size: 20px;
            }
          }
        }
    }
`;


const WrapperImage = styled.picture`
	position: absolute;
	right: 0;
	height: 40vw;
	margin-top: 100px;

	img {
		height: 100%;
	}

	@media (max-width: 1400px) {
		margin-top: 60px;
	}

	@media (max-width: 991px) {
		height: inherit;
		bottom: 0;

		img {
			height: auto;
			width: 80vw;
		}
	}

	@media (max-width: 768px) {
		img {
			width: 100vw;
		}
	}
`;

export default function AparelhosAuditivosSectionOne() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Container>
        <ContentWrapper>
          <h1>Aparelhos auditivos <span>para você se reconectar com os sons da vida.</span></h1>
          <ul>
            <li><img src={CheckIcon} alt="Check" /> Aparelhos auditivos que se ajustam às suas necessidades e estilo de vida</li>
            <li><img src={CheckIcon} alt="Check" /> Qualidade 100% garantida</li>
            <li><img src={CheckIcon} alt="Check" /> Financiamento em até 60x</li>
          </ul>
          <Button onClick={() => navigate("/orcamento")}>Quero receber orçamento</Button>
        </ContentWrapper>
      </Container>

      <WrapperImage>
        <img src={MainImage} alt="" />
      </WrapperImage>
    </Wrapper>
  );
}