import { ReactComponent as JusBrasilLogo } from "@/client/assets/logos/jusbrasil.svg";
import { ReactComponent as PrevidenciaSocialLogo } from "@/client/assets/logos/previdencia-social.svg";
import { ReactComponent as JteLogo } from "@/client/assets/logos/jte.svg";
import { CategoryTitle } from "@/client/styles/layout";
import { DashboardOption, DashboardOptions } from "@/client/styles/Dashboard/home";

export default function LinksUteis(){
  return <>
    <CategoryTitle>Links úteis</CategoryTitle>

    <DashboardOptions>
      <DashboardOption onClick={() => window.open("https://www.jusbrasil.com.br/consulta-processual/")}>
        <JusBrasilLogo />
        <div className="info">
          <h2>Jusbrasil</h2>
          <p>Acompanhe seu processo através do site Jusbrasil.</p>
        </div>
      </DashboardOption>

      <DashboardOption onClick={() => window.open("https://consultaprocessos.inss.gov.br/")}>
        <PrevidenciaSocialLogo />
        <div className="info">
          <h2>Previdência Social</h2>
          <p>Acompanhe seu processo previdenciário aqui.</p>
        </div>
      </DashboardOption>

      <DashboardOption onClick={() => window.open("https://jte.csjt.jus.br/home")}>
        <JteLogo /> 
        <div className="info">
          <h2>Justiça do trabalho</h2>
          <p>Acompanhe seu processo trabalhista aqui.</p>
        </div>
      </DashboardOption>
    </DashboardOptions>
  </>
}