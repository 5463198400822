import { styled } from "styled-components";
import { Button, Container } from "@/client/styles/layout";
import useRouter from "@/client/hooks/useRouter";

const Wrapper = styled.div`
	background: var(--color-white);
	padding: 100px 0 20px;

	@media (max-width: 768px) {
		padding: 80px 0;
	}

	@media (max-width: 576px) {
		padding: 60px 0 0;
	}
`;

const Content = styled.div`
	max-width: 900px;
	margin: 0 auto;

	display: flex;
	flex-direction: column;
	align-items: center;

	.text {
		margin: 0 0 80px;
		width: 100%;
	}

	h2 {
		font-size: 30px;
		color: var(--color-primary);
	}

	p {
		font-size: 20px;
		margin: 30px 0 0;

		span {
			font-weight: 800;
		}
	}

	@media (max-width: 991px) {
		h2 {
			font-size: 24px;
		}
	}

	@media (max-width: 576px) {
		p {
			font-size: 18px;
		}
	}

	@media (max-width: 380px) {
		p {
			font-size: 16px;
		}
	}
`;

export default function QuemSomosSectionTwo()
{
	const router = useRouter();

	return <Wrapper>
		<Container>
			<Content>
				<div className="text">
					<h2>Quem somos</h2>
					<p>No Grupo Dê Ouvidos, há mais de 25 anos, nos dedicamos a transformar vidas através da audição. Acreditamos que a capacidade de ouvir é fundamental para a qualidade de vida e para as conexões humanas. Por isso, nossa missão é clara: garantir que ninguém perca a oportunidade de se reconectar com o mundo ao seu redor.</p>
					<p>Em um mundo cada vez mais tecnológico, sabemos que nada substitui a importância da comunicação clara e das experiências sonoras. Trabalhamos com as melhores tecnologias em aparelhos auditivos, oferecendo soluções inovadoras e adaptadas às necessidades individuais de cada paciente.</p>
					<p>Nosso foco é a excelência no atendimento. Cada consulta, cada exame e cada tratamento são realizados por uma equipe de especialistas altamente qualificados, comprometidos em devolver a você a capacidade de viver plenamente. Acreditamos no poder da audição para resgatar memórias, melhorar o bem-estar e trazer de volta momentos significativos.</p>
					<p><span>Mais do que um tratamento, oferecemos um acompanhamento humanizado e dedicado, garantindo que nossos pacientes se sintam acolhidos e seguros em cada etapa do processo.</span></p>
				</div>
			</Content>
		</Container>
	</Wrapper>
}