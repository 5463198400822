import { useContext, useState } from 'react';

import { Button, Container } from '@/client/styles/layout';
import { ApplicationContext } from '@/client/contexts/ApplicationContext';
import { Wrapper, Content } from './styles';
import actions from "@/client/actions";

import { ReactComponent as Logo } from '@/client/assets/logo.svg';
import { ReactComponent as HomeBoldSvg } from "@/client/assets/icons/home-bold.svg";
import { ReactComponent as HomeLightSvg } from "@/client/assets/icons/home-light.svg";
import { ReactComponent as DocumentsLightSvg } from "@/client/assets/icons/documents-light.svg";
import { ReactComponent as DocumentsBoldSvg } from "@/client/assets/icons/documents-bold.svg";
import { ReactComponent as ConversationsIcon } from "@/client/assets/icons/conversations.svg";
import { ReactComponent as ConversationsBoldIcon } from "@/client/assets/icons/conversations-bold.svg";
import { ReactComponent as ClientsLightSvg } from "@/client/assets/icons/clients-light.svg";
import { ReactComponent as ClientsBoldSvg } from "@/client/assets/icons/clients-bold.svg";
import useRouter from '@/client/hooks/useRouter';
import usePathname from '@/client/hooks/usePathname';
import Profile from '../Profile';

export default function Header({ title, isDashboard, className }: { title: string, isDashboard?: boolean, className?: string }) {
	
	const [menuMobileState, setMenuMobileState] = useState(false);
	
	const { prepared, scroll, isAuthenticated, isAdmin, user, notification } = useContext(ApplicationContext);
	
	const router = useRouter();
	const pathname = usePathname();
	
	function logoClick()
	{
		if(isAdmin && isAuthenticated) return router("/admin");
		if(isDashboard && isAuthenticated) return router("/minha-conta");
		return router("/");
	}
	
	function getMenu()
	{
		if(isAdmin && isAuthenticated) return [
			{ icon: <HomeLightSvg />, iconSelected: <HomeBoldSvg />, title: "Visão Geral", new: false, pathname: "/admin", className: "" },
			{ icon: <ConversationsIcon />, iconSelected: <ConversationsBoldIcon />, title: "Atendimentos", new: notification?.messages, pathname: "/admin/atendimentos", className: "" },
			{ icon: <ClientsLightSvg />, iconSelected: <ClientsBoldSvg />, title: "Clientes", new: false, pathname: "/admin/clientes", className: "" }
		];
		
		if(isDashboard && isAuthenticated) return [
			{ icon: <HomeLightSvg />, iconSelected: <HomeBoldSvg />, title: "Minha conta", new: false, pathname: "/minha-conta", className: "" },
			{ icon: <ConversationsIcon />, iconSelected: <ConversationsBoldIcon />, title: "Atendimento", new: notification?.messages, pathname: "/minha-conta/atendimento", className: "" },
			{ icon: <DocumentsLightSvg />, iconSelected: <DocumentsBoldSvg />, title: "Documentos", new: false, pathname: "/minha-conta/documentos", className: "" },
		];
		
		return [
			{ icon: null, title: "Início", pathname: "/", className: "" },
			{ icon: null, title: "Quem Somos", pathname: "/quem-somos", className: "" },
			{ icon: null, title: "Exames", pathname: "/exames-audiologicos", className: "" },
			{ icon: null, title: "Aparelhos Auditivos", pathname: "/aparelhos-auditivos", className: "" },
			{ icon: null, title: "Contato", pathname: "/contato", className: "" },
			{ icon: null, title: <Button min={true} whatsapp={true} onClick={() => actions}>Preciso de ajuda</Button>, pathname: "/whatsapp", className: "mobile" },
		];
	}
	
	function getActions()
	{
		if(!isAuthenticated) return <Button min={true} whatsapp={true} onClick={() => actions.whatsAppSend()} className="goToMyAccount">Preciso de ajuda</Button>
		else return <Profile user={user} isAdmin={isAdmin} Logout={() => {}} closeMenuMobile={() => setMenuMobileState(false)} redirect={redirect} />
	}
	
	function redirect(redirectFn: () => void, showLoadingLine: boolean) {
		setMenuMobileState(false);
		redirectFn();
	}
	
	return <Wrapper id="header" $prepared={prepared} $scroll={scroll} className={className} $pagetype={((isDashboard || isAdmin) && isAuthenticated) ? "Dashboard" : "Institutional"}>
		<Container>
			<Content $prepared={prepared} $active={menuMobileState ? true : undefined} $pagetype={((isDashboard || isAdmin) && isAuthenticated) ? "Dashboard" : "Institutional"}>
			<Logo className="logo" onClick={() => logoClick()} />
			
			<div className="menu">
			{ getMenu()?.map((item: any, i: number) => 
				item.pathname ? (
					<div key={i} onClick={() => {
						if(pathname !== item.pathname) redirect(() => router(item.pathname), true)
							else setMenuMobileState(false);
					}} className={`button ${item?.className}${item.title === title ? ` active` : ""}`}>
					{item?.icon ? 
						<div className="icon">
						{(item.title === title ? item?.iconSelected : item.icon)}
						{item.new ? <div className="new"></div> : <></>}
						</div>
						: <></>}
						<span>{item.title}</span>
						</div>
					) : (
						<div key={i} onClick={() => redirect(item.onClick, true)} className={`button ${item?.className}${item.title === title ? ` active` : ""}`}>
						{item?.icon ? (item.title === title ? item?.iconSelected : item.icon) : ""}
						<span>{item.title}</span>
						</div>
					)
				)}
				</div>
				
				<div className="actions">{getActions()}</div>
				
				<div className="toogleMobile" onClick={() => setMenuMobileState(!menuMobileState)}>
				<div></div>
				<div></div>
				<div></div>
			</div>
			</Content>
		</Container>
	</Wrapper>
	}