import { useState, useEffect } from "react";
import { styled } from "styled-components";

import { Button, Container } from "@/client/styles/layout";
import Input from "@/client/components/Inputs/official";
import InputPhone from "@/client/components/Inputs/telephone";
import { ContactValidation } from "@/core/helpers/validations/contact";

import BackgroundPattern from "@/client/assets/images/background-pattern.png";
import CheckBox from "@/client/components/Inputs/checkbox";

const SectionWrapper = styled.section`
    padding: 120px 0;
    background-image: url(${BackgroundPattern});
    background-size: cover;
    background-position: center;
    background-color: var(--color-primary-light);
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 576px) {
        padding: 20px 0;
    }
`;

const FormWrapper = styled.div`
    max-width: 640px;
    margin: 0 auto;
    text-align: center;
    color: #0a8172;
    padding: 50px 40px 40px;
    background: #f3f3f3;
    border-radius: 20px;
  
    @media (max-width: 576px) {
      	padding: 10px 10px 40px;
		margin: 0 -10px;
    }
`;

const Title = styled.h2`
    font-size: 2.5rem;
    font-weight: 800;
    margin-bottom: 10px;

    @media (max-width: 576px) {
		padding: 20px;
		margin: 0 auto;
		max-width: 300px;
    }
`;

const Subtitle = styled.p`
    font-size: 1.4rem;
    margin-bottom: 30px;
    margin: 0 auto 40px;

    @media (max-width: 576px) {
      	max-width: 300px;
    }
`;

const StyledForm = styled.form`

    .textarea ~ ._label {
        background-color: var(--color-white);
    }

    h3 {
      margin: 20px 0 30px;
      font-weight: 700;
      font-size: 1.2rem;
    }

    Button {
      margin: 40px 0 0;
      max-width: 100%;
      width: 100%;
    }

    @media (max-width: 576px) {
      Button {
        padding: 20px 10px;
        font-size: 1.2rem;
      }
    }
`;

export default function Form7Days() {

  const [form, setForm] = useState({}) as any;
  const [errors, setErrors] = useState({}) as any;
  const [loading, setLoading] = useState(false) as any;
  
  // const initialMessage = useSearchParams().get('text'); // Caso queira utilizar
  const initialMessage = ""; // Caso precise de valor inicial para a mensagem

  async function handleSubmit(ev: any) {
    ev.preventDefault();

    if (loading) return;    
    setLoading(true);

    try {
      var hasErrors = ContactValidation(form);
      if (Object.keys(hasErrors).length > 0) throw hasErrors; 

      // await actions.api.post("/contacts/send", form).then(response => {
      //   if(response.data.success) return router.push(`/obrigado?${response.data.params}`);
        
      //   throw response.data;
      // });
    } catch (e: any) {
      setErrors(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    setErrors({});
  }, [form]);

  return (
    <SectionWrapper>
      <Container>
        <FormWrapper>
          <Title>Teste por 7 dias Grátis!</Title>
          <Subtitle>Preencha os campos abaixo e entraremos em contato!</Subtitle>
          <StyledForm onSubmit={handleSubmit}>
            
            <div className="fields">
              <Input 
                error={errors?.name} 
                type="text" 
                placeholder="Nome completo *" 
                textTransform="capitalize"
                onChange={(e: any) => setForm({ ...form, name: e.target.value })} 
              />
              <Input 
                error={errors?.email} 
                type="email" 
                placeholder="E-mail *"
                textTransform="lowercase"
                onChange={(e: any) => setForm({ ...form, email: e.target.value })} 
              />
              <InputPhone 
                error={errors?.phone} 
                onChange={(phone: any) => setForm({ ...form, phone })} 
              />
            </div>
            
            <h3>Escolha um local para atendimento:</h3>

            <CheckBox
              selected={form.localAgendamento}
              options={["São Bernardo do Campo", "Penha", "Moema", "Lapa"]}
              onChange={(newOption) => setForm({ ...form, localAgendamento: newOption })}
              />

            <Button loading={loading ? true : undefined}>Enviar</Button>
          </StyledForm>
        </FormWrapper>
      </Container>
    </SectionWrapper>
  );
}