import { styled } from "styled-components";

export const WrapperInstitutional = styled.div`
	height: calc(100vh - var(--header-size));
	overflow: auto;

	@media (max-width: 991px) {
		height: calc(100vh - var(--header-size) + 20px);
	}
`;

export const Wrapper = styled.div`
	padding: 80px 0;
	height: calc(100vh - var(--header-size));
	background: var(--color-dashboardBackground);
	color: #333;

	overflow: auto;

	h1 {
		font-size: 30px;
	}

	@media (max-width: 991px) {
		height: 100vh;
		padding: 60px 0 260px;
	}

	@media (max-width: 768px) {
		h1 {
			font-size: 26px;
		}
	}

	@media (max-width: 576px) {
		h1 {
			font-size: 24px;
		}
	}
`;

export const ListEmpty = styled.div`
	width: 100%;
	background: var(--color-white);
	padding: 50px 30px;

	border-radius: 10px;

	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	svg {
		height: 80px;
		margin: 0 0 30px;
	}

	h4 {
		font-size: 20px;
	}

	button {
		border-radius: 100px;
	}
`;

export const List = styled.div<{ columns?: number, gap?: number }>`
	display: grid;
	grid-template-columns: ${({ columns }) => `repeat(${columns || 1}, 1fr)`};
	gap: ${({ gap }) => `${gap || 0}px`};

	@media (max-width: 991px) {
		grid-template-columns: 1fr;
	} 
`;

export const DocumentItem = styled.div`
	padding: 10px;
	border-radius: 10px;
	margin: 0 0 10px;
	background: var(--color-white);

	--image-size: 50px;
	
	display: grid;
	grid-template-columns: var(--image-size) 2fr 100px 1fr 0 1fr;
	align-items: center;
	gap: 20px;

	.image {
		object-fit: cover;
		min-width: var(--image-size);
		max-width: var(--image-size);
		height: var(--image-size);
		
		border-radius: 8px;
		background-color: rgba(0,0,0,.1);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		&.pdf {
			background: #8e5554;

			display: flex;
			justify-content: center;
			align-items: center;

			&::after {
				content: "PDF";
				font-size: 14px;
				color: #FFF;
			}
		}
	}

	.title {
		font-weight: 800;
		font-size: 16px;
		opacity: .9;
	}

	.date, .size {
		font-size: 16px;
		font-weight: 800;
		text-align: center;
		opacity: .9;
	}

	.actions {
		display: flex;
		justify-content: flex-end;

		button {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-left: 10px;

			font-size: 14px;
			font-weight: 800;
			padding: 12px 20px;
			border: none;
			border-radius: 8px;

			opacity: .8;
			transition: 200ms ease-in-out;

			&:hover {
				opacity: 1;
			}

			cursor: pointer;

			svg {
				margin-right: 10px;

				--svg-size: 16px;
				min-width: var(--svg-size);
				max-width: var(--svg-size);
				height: var(--svg-size);

				fill: #333;
				
				path {
					fill: #333;
				}
			}

			&.download {
				background: #D9D9D9;
				color: #333;
			}

			&.remove {
				background: #C6AAA9;
				color: var(--color-primary);

				svg {
					fill: var(--color-primary);
				
					path {
						fill: var(--color-primary);
					}
				}
			}
		}
	}

	@media (max-width: 1280px) {
		grid-template-columns: auto 1fr;
	}

	@media (max-width: 991px) {
		gap: 10px;

		.title {
			text-align: right;
		}

		.date {
			text-align: right;
		}

		.size {
			text-align: left;
			font-size: 14px;
		}
		
		.actions {
			margin: 10px 0 0;
		}
	}

`;

export const ClientItem = styled.div`
	padding: 12px 12px 12px 20px;
	width: 100%;
	background: #fff;
	border-radius: 8px;

	display: grid;
	grid-template-columns: 1.5fr 2fr 1.2fr auto;
	gap: 30px;

	margin: 0 0 10px;

	span {
		font-size: 16px;
		line-height: 1.4;
		text-overflow: ellipsis;
		overflow: hidden;

		span {
			font-weight: 800;
		}
	}

	.status {
		&.success { color: rgba(82, 171, 86, 1); }
		&.in-progress { color: rgba(220, 164, 77, 1); }
		&.analytics { color: rgba(142, 85, 84, 1); }
	}

	.data {
		display: flex;
		flex-direction: column;

		max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
	}

	.actions {
		display: flex;
		justify-content: flex-end;

		button {
			border: none;

			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 15px;
			font-weight: 800;
			width: max-content;
			padding: 10px 20px;
			border-radius: 10px;

			cursor: pointer;
			background: rgba(142, 85, 84, 1);
			color: #fff;

			transition: 200ms ease-in-out;

			&:hover {
				background: var(--color-primary-light);
			}

			svg {
				min-width: 18px;
				max-width: 18px;
				height: 18px;
				fill: #fff;
				margin: 0 10px 0 0;

				path {
					fill: #fff;
				}
			}
		}
	}

	@media (max-width: 1280px) {
		grid-template-columns: 1fr 1fr;
		gap: 10px;
	}

	@media (max-width: 991px) {
		grid-template-columns: 1fr;
		gap: 10px;
	}

	@media (max-width: 380px) {
		padding: 15px;
	}
`;