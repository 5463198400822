import React, { useEffect, useRef } from "react";

export default function EditableElement({ children, onChange, limit, cb }: any) {
  const element = useRef() as any;
  let elements = React.Children.toArray(children) as any;

  if (elements.length > 1) {
    throw Error("Can't have more than one child");
  }

  const onMouseUp = () => {
    const value = element.current?.value || element.current?.innerText;

    if(value?.length > limit) {
      element.current.innerText = value?.slice(0, limit);
      element.current.blur()
      return onChange(value?.slice(0, limit))
    }

    onChange(value);
  };

  useEffect(() => {
    const value = element.current?.value || element.current?.innerText;
    if(value?.length > limit) return;
    onChange(value);

    cb(element)
  }, []);

  elements = React.cloneElement(elements[0], {
    contentEditable: true,
    suppressContentEditableWarning: true,
    ref: element,
    onKeyUp: onMouseUp
  });

  return elements;
};